<template>
  <v-container fluid>
    <monitor-events-syslog-table
      :headers="headers"
      :expanded-headers="expanded"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size, $event.order, $event.orderBy)"
      @onSearch="onSearch"
    />
  </v-container>
</template>

<script>
import monitorEventsSyslogTable from "./syslog/table";

import { getAll } from "@/api/monitor/allSyslog";
import { Errors } from "@/utils/error";

export default {
  components: {
    monitorEventsSyslogTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,
      search: undefined,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    monitorEventsSyslogTable: {
      get() {
        return this.$store.state.tables.monitorEventsSyslogTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorEventsSyslogTable",
          value: val,
        });
      },
    },
  },
  watch: {
    monitorEventsSyslogTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size, sortOrder, sortBy) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      let order = null;
      let by = null;
      if (sortOrder && sortBy) {
        order = sortOrder;
        by = sortBy;
      }
      getAll(newPage, size, order, by, this.search)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    onSearch(event) {
      this.search = event;
      this.page = 1;
      this.loadData(this.page, this.size);
    }
  },
};
</script>
