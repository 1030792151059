<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <SearchBar
        search-label="Search logs"
        @onSearch="onSearch"
        :is-loading="loading"
      />
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.received_at="{ item }">
          {{ item.received_at }}
        </template>
        <template v-slot:item.from="{ item }">
          {{ item.from }}
        </template>
        <template v-slot:item.message_short="{ item }">
          {{ item.message_short }}
        </template>
        <template v-slot:item.message_full="{ item }">
          {{ item.message_full }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            id="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'received_at'"
                      width="150"
                      class="pl-3"
                    >
                      Created:
                    </td>
                    <td v-if="expandItem.value === 'received_at'">
                      {{ item.received_at }}
                    </td>

                    <td
                      v-if="expandItem.value === 'from'"
                      width="150"
                      class="pl-3"
                    >
                      From:
                    </td>
                    <td v-if="expandItem.value === 'from'">
                      {{ item.from ? item.from : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'message_short'"
                      width="150"
                      class="pl-3"
                    >
                      Short Message:
                    </td>
                    <td v-if="expandItem.value === 'message_short'">
                      {{ item.message_short ? item.message_short : "-" }}
                    </td>

                    <td
                      v-if="expandItem.value === 'message_full'"
                      width="150"
                      class="pl-3"
                    >
                      Full Message:
                    </td>
                    <td v-if="expandItem.value === 'message_full'">
                      {{ item.message_full ? item.message_full : "-" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import SearchBar from "@/components/SearchBar";

export default {
  components: {
    SearchBar,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      options: {},

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {},
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.options.page = newValue;
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.options.itemsPerPage = this.sizes[newValue];
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
  },
  methods: {
    onSearch(event) {
      this.$emit("onSearch", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.synapsa-table.theme--dark ::v-deep {
  #expand-block {
    background-color: #66666999 !important;
  }
  .v-data-table {
    background-color: transparent !important;
  }
}
.synapsa-table.theme--light ::v-deep {
  #expand-block {
    background-color: #f7f7f7 !important;
  }
  .v-data-table {
    background-color: transparent !important;
  }
}
</style>
